/* StyledInput.css */
.custom-styled-input .MuiInputLabel-root {
  color: grey !important;
  line-height: 2.4375em;
}
.custom-styled-input.select-field .MuiInputLabel-root{
  line-height: 1.8 !important;
}
.custom-styled-input .MuiInputLabel-root.Mui-focused {
  color: grey!important;
 
}

.custom-styled-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
.custom-styled-input .MuiOutlinedInput-input,
.custom-styled-input .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.custom-styled-input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiSelect-select .MuiSelect-outlined .MuiInputBase-input .MuiOutlinedInput-input {
  border-color: grey;
  border-radius: 13px;
  padding: 15px 10px;
}

.error-input .custom-styled-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
.error-input .custom-styled-input .MuiOutlinedInput-root .MuiOutlinedInput-input,
.error-input .custom-styled-input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
  color: red !important;
}

.custom-styled-input .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.error-input .custom-styled-input .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: grey;
}

.error-input .custom-styled-input .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.error-input .custom-styled-input .MuiOutlinedInput-root:hover .MuiOutlinedInput-input {
  border-color: red !important;
}
.error-input .custom-styled-input .MuiInputLabel-root {
  color: red !important;
}
.custom-styled-input .MuiInputBase-input{
  color: black !important;
  font-size: 15px!important;
}
.error-message{
  margin-top: 4px;
}
.custom-styled-input{
  border-radius: 13px !important;
}
/* .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  border-color: grey!important;
  border-radius: 13px!important;

} */


/* StyledSelect.css */
/* StyledInput.css */


.custom-select .MuiInputLabel-root.Mui-focused {
  color: black !important;
}

.custom-select .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
.custom-select .MuiOutlinedInput-input,
.custom-select .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.custom-select .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  border-radius: 13px;
  padding: 14px 10px;
  text-align: left;
}

/* StyledInput.css */
/* ... other styles ... */

.custom-select .MuiInputLabel-root {
  color: black !important;
  line-height: 2.4375em;
}

.custom-select .MuiInputLabel-root.Mui-focused {
  color: black !important;
}


.custom-select .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: grey !important;
}

/* ... other styles ... */
