html {
  box-sizing: border-box;
  background-color: #eee;

}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.App {
  font-family: 'Raleway', sans-serif ;
  text-align: center;

  width: 100%;
}

.login-form {
  width: 100%;
}

.form-title {
  margin-top: 0;
  margin-bottom: 15px;
  text-align: center;
  color: #007AFE;
}

.form-control {
  width: 100%;
}

.form-control input {
  border-radius: 5px;
  height: 40px;
  width: 100%;
  padding: 2px 10px;
  border: none;
}

.login-btn {
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  background: rgb(60, 173, 239);
  color: #fff;
}

.nav-items {
  padding-left: 8px;
}

.nav-items li {
  list-style: none;
  line-height: 2;
  margin-bottom: 13px;
}

.link-style {
  margin-right: 10px;
  border-radius: 10px;
  display: flex;
  text-decoration: none;
  padding: 1px 10px;
  transition: background-color 0.2s, color 0.2s;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #808285;
  align-items: center;
  gap: 14px
}


.link-style:hover,
.link-style.active {
  background-color: #007AFE;
  color: white;
}

.link-style:hover .custom-icon,
.link-style.active .custom-icon {
  color: white;
}
.custom-icon{
  width:18px;
  height:18px;
}
.login-container {
  max-width: 475px;
  position: absolute;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%);
  height: fit-content !important;
}

.logo {
  height: 50px;
  width: 50px;
  margin-bottom: 15px;
}

.block-container {
  width: 100%;
  /* margin-left: 342px; */
  background: #fff;
  padding: 16px;
  border-radius: 10px;
  margin: auto;
  height: 100%;
  padding-top: 16px;
}

.table {
  padding-top: 14px;
  background: white;
  border-radius: 10px;
  height: calc(100% - 100px);
}
.table .MuiDataGrid-columnHeaders{
  background-color: #eee;
}
.block-heading {
  text-align: left;
  margin: 0px 10px 24px 0px;
  font-size: 1.3rem;
}
.block-heading.dashboard{
  margin: 0;
}

.header-cell {
  font-weight: 700;
  font-size: 15px;
  background-color: #eee;
}

.main-container {
  /* width: calc(100% - 340px);
  margin: auto */
  position: relative;
}

.dashboard-container {
  margin: auto;
}
.dashboard-container .row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}
.dashboard-container .followUp{
  padding: 24px 16px;
  background: #f4faff;
  box-shadow: 0px 0px 12px rgba(0,0,0,0.06);
  border-radius: 10px;
  margin-top: 16px;
}
.dashboard-container .followUp .MuiTabs-flexContainer{
  background-color: #fff;
  box-shadow: 0px 0px 12px rgba(0,0,0,0.06);
  border-radius: 10px;
  width: fit-content;
}
.dashboard-container .followUp .MuiTabs-root{
  margin-top: 30px;
}
.dashboard-container .followUp .MuiTab-root{
  padding: 12px 20px;
  font-size: 13px;
}
.dashboard-container .followUp .MuiTab-root.Mui-selected{
  color: #007AFE;
  background-color: #d6eaff;
  border-radius: 10px;
}
.dashboard-container .followUp .MuiTabs-indicator{
  display: none;
}
.dashboard-container .col{
  padding: 24px 16px;
  background: #fff;
  box-shadow: 0px 0px 12px rgba(0,0,0,0.06);
  border-radius: 10px;
  height: 23vw;
  width: 26%;
  position: relative;
}
.dashboard-container .col.big{
  width: 36%;
}
.dashboard-container .col.col-half{
  width: 49%;
}
.dashboard-container .col-heading{
  font-size: 14px;
  color: #2c3849;
  margin-top: 0;
}
.dashboard-container .col-head .MuiButtonBase-root{
  position: absolute;
  top: 14px;
  right: 16px;
}
.dashboard-container .col .table{
  height: calc(100% - 24px);
}

.sub-header {
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}
.sub-header .search-wrapper{
  display: flex;
  gap: 12px;
  width: calc(100% - 180px);
}
.sub-header .search-wrapper .MuiTextField-root{
  max-width: 250px;
  width: 48%;
}
.nav-wrapper{
  height: calc(100dvh - 170px);
  overflow-y: auto;
}
.nav-wrapper::-webkit-scrollbar{
  width: 4px;
}
.nav-wrapper::-webkit-scrollbar-thumb{
  border-radius: 2px;
  background: #ccc;
}
.MuiDataGrid-checkboxInput {
  background-color: #eee;
}

.add-btn {

  font-size: 0.9375rem !important;
  display: flex;
  max-height: 36px;
  background-color: #007AFE;
  gap: 10px;
}

.css-i4bv87-MuiSvgIcon-root {
  color: #007AFE;
}

.d-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.custom-icon-button {
  font-size: 37px;
}

.custom-search-input {
  height: 30px;
  /* Adjust the height value as needed */
}

/* add form css */
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout-btn {
  position: absolute;
  bottom: 5px
}

.Input-field {
  width: 100%;
  margin-bottom: 18px !important;
  margin: auto;
}
.Input-field fieldset{
  top: -3px !important;
}

.error-input {
  text-align: left;
  color: red;
  border-color: red;
  font-size: 14px;
}

.input-box {
  color: grey;
  border-color: grey;
}

.alert-danger {
  margin-top: 10px;
  color: red;
  text-align: center;
  padding: 10px;
  background-color: #FEF8F9;
}

.bottom-nav-items {
  position: absolute;
  margin-bottom: 5px;
}

/*-------------Add form css--------------*/
.form-subheading {
  text-align: left;
  border-bottom: 1px solid #eee;
  padding: 15px 0;
  font-size: 1.3rem;
  align-items: center;
  
}
.form-subheading .MuiIconButton-root{
  background: #eef7ff;
}
.add-user-form.view-client-container{
  margin-top: 0;
  padding: 0 20px;
  height: 100%;
}
.add-user-form {
  background-color: #fff;
  text-align: center;
  width: 43%;
  margin: auto;
  border-radius: 10px;
  padding: 1px 16px 22px 16px;
  margin-top: 16px;
  height: fit-content;
}
.add-user-form.user{
  max-width: 550px;
}
.email-log-container .head{
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}
.email-log-container .head h2{
  margin: 0;
}
.email-log-container .head button{
  background: #fff;
  box-shadow: 0px 2px 6px rgba(0,0,0,0.1);
  padding: 6px;
}
.email-log-container .head button svg{
  font-size: 2.0rem;
}
.email-log-container .add-user-form{
  height: calc(100% - 106px);
  padding: 10px 20px;
  overflow-y: auto;
  margin: 0;
  margin-top: 10px;
}
.email-log-container .add-user-form::-webkit-scrollbar{
  width: 4px;
}
.email-log-container .add-user-form::-webkit-scrollbar-thumb{
  background: #ccc;
  border-radius: 3px;
}
.email-log-container .add-user-form h6{
  margin-bottom: 16px;
  white-space: initial;
  word-break: break-word;
}
.email-log-container .add-user-form h6 .detail{
  font-size: 14px;
  text-transform: capitalize;
}
.email-log-container .body-content p, .email-log-container .body-content strong, .email-log-container .body-content li, .email-log-container .body-content a{
  font-size: 14px;
}
.client-log-header{
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
}
.client-log-header .email-heading{
  width: calc(100% - 150px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.client-log-header .add-btn{
  font-size: 13px !important;
}
.add-user-form.send-email{
  width: 100%;
  max-width: 1000px;
}
.add-user-form.view-client {
  margin-left: 0;
  padding: 0;
}
.add-user-form .form-wrapper{
  height: calc(100% - 100px);
  overflow-y: auto;
}
.add-user-form .form-wrapper::-webkit-scrollbar{
  width: 4px;
}
.add-user-form .form-wrapper::-webkit-scrollbar-thumb{
  border-radius: 2px;
  background: #ccc;
}
.add-client .fields-wrapper::-webkit-scrollbar{
  width: 4px;
}
.add-client .fields-wrapper::-webkit-scrollbar-thumb{
  border-radius: 2px;
  background: #ccc;
}
.ql-toolbar{
  text-align: left;
  border-top-right-radius: 13px;
  border-top-left-radius: 13px;
}
.ql-container{
  border-bottom-right-radius: 13px;
  border-bottom-left-radius: 13px;
}
.ql-editor{
  height: 330px !important;
  overflow-y: auto;
}
.ql-editor::-webkit-scrollbar{
  width: 5px;
}
.ql-editor::-webkit-scrollbar-thumb{
  background: #ccc;
  border-radius: 2.5px;
}
.inner-container {
  width: 60%;
  margin: auto;
}

.add-client {
  width: 100% !important;
  height: calc(100% - 30px);
  padding-bottom: 10px !important;
}
.add-client .form-wrapper{
  height: calc(100% - 100px);
}
.add-client .fields-wrapper{
  height: calc(100% - 70px);
  overflow-x: auto;
}
.add-client .form-wrapper form{
  height: 100%;
}
/*-------------Add form css--------------*/
.full-width-field{
  margin-top: -16px !important;
}
.subheading {
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.sub-container,.main-wrapper {
  padding: 15px;
}

.company-container {
  padding: 15px;
  border: 1px solid #eee;
  margin: 35px;
  border-radius: 13px;
}
.profile-image-col{
  display: flex;
  flex-direction: column!important;
  gap: 27px;
}
.dialog-box{
  border-radius: 10px;
}

.loader-text{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.date-picker-container input{
  padding: 12px;
}
.date-picker-container .MuiFormHelperText-root{
  margin-top: 8px;
}
.date-picker-container fieldset{
  border: 1px solid grey;
  border-radius: 10px;
  min-width: 207px;

}
.custom-grid{
  padding: 12px;
}
.custom-grid h6{
  display: flex;
  flex-direction: column;
}
.custom-grid h3{
  font-size: 18px;
}
.email-log-container .body-content img{
  width: 100%;
}
.grid-container
{
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: calc(100% - 78px);
  align-items: flex-end;
}
.grid-container::-webkit-scrollbar{
  width: 4px;
  height: 4px;
}
.grid-container::-webkit-scrollbar-thumb{
  background: #ccc;
  border-radius: 2px;
}
.grid-container .MuiGrid-container{
  margin: 0;
  width: 100%;
}
.report-heading {
  text-align: left;
  margin: 0px 0px 0px 16px;
  height: fit-content;
  font-size: 1.2rem;
}
.custom-select {
  height: 40px; /* Adjust the height as needed */
  
    padding: '8px';
    display: 'flex';
    align-items: 'center';
}
.custom-select{
  padding: '8px 12px';
   line-height: '1.5';
}
.phone-input-container {
  display: flex;
  align-items: center;
  height: 48px; 
  border: 1px solid #ccc;
  border-radius: 4px; 
  padding: 8px; 
}
 
  .phone-input-container .StyledInput {
    flex-grow: 1; 
  }

  
  .phone-input-container  .custom-phone-input {
    flex-shrink: 0; 
    border: none;
  }

  .error-message {
    color: red; 
    font-size: 14px;
    margin-top: 5px; 
  }
  .spinner-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
  }
  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error{
    color: red!important;
  }
  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color: black!important;
  }
 .custom-select-client .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input{
  padding: 8px 10px;
  width: calc(100% - 40px);
 }
 .custom-select-client .MuiInputLabel-root{
  top: -7px!important;
  width: calc(100% - 40px);
 }
 .custom-select-client fieldset{
  border-radius: 5px !important;
 }
 .alert-message{
  color: red;
  text-align: left;
  margin-bottom: 11px;
  font-size: 14px;
 }
 #root{
 position: relative;
  height: 100dvh;
  overflow: hidden;
}
.no-data-msg{
  text-align: center;
}
.css-kzyyzb-MuiDataGrid-root .MuiDataGrid-columnHeader:focus{
  outline: none!important;
}
.MuiDataGrid-root .MuiDataGrid-cell:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus-within, .MuiDataGrid-root, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within{
  outline: none !important;
}
.link-style-back {
  color: #007AFE;
  text-decoration: underline;
  cursor: pointer;
  text-align: left;
  margin-left: 16px
}

.back-button {
  display: flex;
  flex-direction: column;
}
.table-container{
  max-height: 630px;
  overflow-y: auto;
  height: calc(100% - 64px)
}
.table-container::-webkit-scrollbar{
  width: 4px;
}
.table-container::-webkit-scrollbar-thumb{
  background: #ccc;
  border-radius: 2px;
}
.custom-style {
  margin-bottom: 13px;
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar{
  width: 4px;
  height: 4px;
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb{
  background: #ccc;
  border-radius: 2px;
}
.menu-btn{
  background: #fff;  
  position: fixed;
  width: 34px;
  height: 34px;
  top: 25px;
  left: 16px;
  border-radius: 50%;
  box-shadow: 0px 0px 12px rgb(0, 0, 0, 0.06);
}
.menu-btn svg{
  font-size: 19px;
}
.input-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 280px;
  gap: 12px;
}
.input-container .react-datepicker-wrapper, .input-container .react-datepicker-wrapper input{
  width: 100%;
}
.send-email-btn{
  flex-wrap: wrap;
  gap: 12px;
}
.send-email-btn button{
  font-size: 13px !important;
  margin: 0 !important;
}
.schedule-email .table{
  height: calc(100% - 44px) !important;
  padding-top: 8px;
}
.client-details{
  text-align: left;
  margin-top: 0 !important;
  border-radius: 16px;
  padding: 4px 14px;
  background: #f4f9ff;
}
.client-details h3{
  font-size: 15px;
  color: #333333;
}
.client-details p{
  font-size: 13px;
  color: #575757;
  letter-spacing: 0.2px;
}
.client-details b{
  color: #575757;
  margin-right: 4px;
  display: inline-block;
}
.client-details p a{
  color: #575757;
}
.text-area{
  padding-left: 16px;
}
.text-area .MuiTextarea-root{
  background-color: unset !important;
  border-color: grey !important;
  font-size: 14px;
}
.email-log-table{
  height: calc(100% - 108px);
  overflow: auto;
}
.email-log-table::-webkit-scrollbar{
  width: 4px;
}
.email-log-table::-webkit-scrollbar-thumb{
  background-color: #ccc;
  border-radius: 2px;
}
.email-log-table .collapse-table-container{
  border-right: 1px solid rgba(224, 224, 224, 1);
  border-left: 1px solid rgba(224, 224, 224, 1);
}
.collapse-table-container{
  position: relative;
  margin-top: 4px !important;
  height: calc(100% - 50px);
}
.collapse-table-container thead{
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #eee;
}
.collapse-table-container tbody{
  position: relative;
  z-index: 1;
}
.collapse-table-container tbody tr th, .collapse-table-container tbody tr td{
  padding: 4px 8px;
  min-width: 150px;
}
.client-table-container .collapse-table-container tbody tr th, .client-table-container .collapse-table-container tbody tr td, .client-table-container .collapse-table-container thead tr th{
  font-size: 13px;
  color: #5a5a5a;
}
.collapse-table-container thead tr th{
  padding: 8px;
  min-width: 150px;
}
.collapse-table-container tbody tr td:first-child, .collapse-table-container thead tr th:first-child{
  width: 50px;
  min-width: unset;
}
.collapse-table-container tbody tr td:last-child, .collapse-table-container thead tr th:last-child{
  width: 50px;
  min-width: unset;
}
.table-status{
  padding: 8px 16px;
  border-radius: 16px;
  color: #fff;
  line-height: 1.2 !important;
}
.delivered-card {
  background-color: rgba(151, 199, 96, 0.5)!important;
  color: #354739!important; /* Darker shade of green for text and icon */
}

.soft-bounced-card {
  background-color: rgba(255, 213, 79, 0.5)!important; /* Transparent yellow-orange */
  color: #704f2e!important;
}

.hard-bounced-card {
  background-color: rgba(239, 83, 80, 0.5)!important; /* Transparent red */
  color: #4a2a2d!important;
}

.processed-card {
  background-color: rgba(79, 195, 247, 0.5) !important;
  color: #2e475a !important;
}
.click-card {
  background-color: #e7fffe !important;
  color: #29938e!important;
}
.unsubscribe-card{
  background-color: #ffe8e7 !important;
  color: #830d0d!important;
}
.resubscribe-card{
  background-color: #f1e7ff !important;
  color: #471558!important;
}
.pending-card{
  background-color: #e7f5ff !important;
  color: #0d5283!important;
}
.open-card{
  background-color: #fffde7 !important;
  color: #8a8227!important;
}
.bounce-card{
  background-color: #b8d4ff !important;
  color: #2c3849!important;
}
.card-count {
  font-weight: bold!important;
}
.react-datepicker-popper{
  z-index: 102 !important;
}
.center-label .MuiInputLabel-formControl{
  top: -8px !important;
}
.date-picker-container.search-bar input{
  border-radius: 5px !important;
  border: 1px solid grey;
  padding: 11px 16px;
}
.dashboard-card{
  padding: 8px;
  padding-bottom: 4px;  
  box-shadow: 0px 0px 8px rgba(0,0,0,0.07) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px !important;
}
.dashboard-card .MuiCardContent-root{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px !important;
}
.dashboard-card p{
  font-size: 15px;
}
.dashboard-card .card-info{
  width: calc(100% - 60px);
}
.dashboard-card .MuiTypography-root{
  margin-bottom: 2px !important;
  text-align: right;
}
.filter-select label{
  line-height: 1.5 !important;
  font-size: 15px;
}
.filter-select input{
  font-size: 15px;
}
.filter-select .MuiInputBase-root{
  padding: 0 9px !important;
  padding-right: 39px !important;
}
.popup-wrapper .add-user-form{
  margin-top: 0;
  padding: 0;
}
.popup-wrapper .add-user-form h2{
  margin-top: 0;
  padding-top: 0;
}
.popup-wrapper .MuiTypography-h5{
  font-weight: 700;
  font-size: 1.3rem;
}
.popup-wrapper .MuiPaper-root{
  max-width: 1080px !important;
  max-height: calc(100% - 40px) !important;
}
.popup-wrapper.filter .MuiPaper-root{
  max-width: 560px !important;
  height: fit-content;
}
.popup-wrapper.chart .MuiPaper-root{
  height: calc(100% - 80px) !important;
  max-height: 760px !important;
}
.popup-wrapper.column-filter .MuiPaper-root{
  max-width: 380px !important;
  height: fit-content;
}
.popup-filter .input-container{
  flex-direction: column;
  display: flex;
}
.column-filter-wrapper{
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.MuiDialogContent-root::-webkit-scrollbar{
  width: 6px;
}
.MuiDialogContent-root::-webkit-scrollbar-thumb{
  background: #ccc;
  border-radius: 3px;
}
.popup-content{
  min-height: 240px;
  height: calc(100% - 20px);
}
.date-range-picker-wrapper input{
  padding: 14px;
}
.date-range-picker-wrapper fieldset{
  border-radius: 12px;
  border-color: grey;
}
.dashbaord-filter{
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: right;
}
.dashbaord-filter-wrapper{
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.dashbaord-filter-wrapper button{
  cursor: pointer;
  background-color: #fff;
  color: #007AFE;
  padding: 6px 16px;
  font-size: 14px;
  border-radius: 16px;
  border: none;
  box-shadow: 0px 0px 6px rgba(0,0,0,0.08);
}
.dashbaord-filter-wrapper button.active{
  background: #007AFE;
  color: #fff;
}
.sidebar-drawer .MuiDrawer-paper::-webkit-scrollbar{
  width: 5px;
}
.sidebar-drawer .MuiDrawer-paper::-webkit-scrollbar-thumb{
  background: #ccc;
  border-radius: 2.5px;
}
.activity-table.table{
  padding-top: 5px;
  height: calc(100% - 66px);
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
}
.activity-table.table::-webkit-scrollbar{
  width: 5px;
}
.activity-table.table::-webkit-scrollbar-thumb{
  background-color: #ccc;
  border-radius: 2.5px;
}
.user-panels > .MuiBox-root{
  padding: 0;
}
.email-form{
  display: flex;
  justify-content: space-between;
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 60%;
  position: relative;
  margin-top: 8px;
}
.apexcharts-toolbar{
  display: none !important;
}
.input-container .custom-select-client{
  margin-top: 0 !important;
}
.group-records-wrapper{
  background: #fff;
  box-shadow: 0px 0px 12px rgba(0,0,0,0.06);
  border-radius: 10px;
  padding: 8px 12px;
  margin-bottom: 10px;
  height: fit-content;
}
.group-records-head{
  border-bottom: 1px solid #ddd;
  padding: 14px 8px;
  padding-top: 12px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.group-records-head p{
  font-weight: 600;
  width: unset !important;
  overflow: hidden;
  text-align: left !important;
  text-overflow: ellipsis;
  max-height: 30px;
  margin: 0;
  padding: 0 8px;
}
/* .group-records-head .right-col{
  width: 36%;
} */
.group-records-wrapper table{
  margin-bottom: 8px;
}
.group-records-wrapper .group-events{
  position: relative;
}
.group-records-wrapper .group-event::after{
  position: absolute;
  left: 16px;
  content: "";
  bottom: 0;
  background-color: #ddd;
  border-radius: 2px;
  width: 4px;
  height: 100%;
}
.group-records-wrapper .group-event::before{
  position: absolute;
  left: 11px;
  z-index: 1;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  background-color: #007AFE;
  border: 2px solid #fff;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.group-records-wrapper .group-event{
  position: relative;
  display: flex;
  max-width: 420px;
  justify-content: space-between;
  padding: 5px 10px;
  padding-left: 34px;
  /* max-width: 400px; */
  align-items: center;
}
.group-records-wrapper .group-event p{
  width: fit-content !important;
  font-size: 13px;
  margin: 0;
}
.group-event .table-status{
  text-transform: capitalize;
  font-weight: 600;
}
.date-filter{
  display: flex;
  align-items: center;
  gap: 6px;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
}
.date-filter h2{
  margin-bottom: 0;
}
.custom-styled-input .error-message{
  font-size: 0.75rem;
  margin-top: 10px;
}
.dashboard-container .followUp .MuiTabs-scroller{
  overflow-x: auto !important;
}
.filter-input label{
  top: -4px;
}
.filter-input .MuiSelect-select, .filter-input fieldset{
  border-radius: 6px !important;
}
.dashbaord-filter.email-log{
  width: 100%;
  justify-content: space-between;
  min-height: 51px;
  margin-bottom: 12px;
}
.dashbaord-filter.email-log .dashbaord-filter-wrapper button{
    padding: 10px 20px;
    border-radius: 20px;
}
.dashbaord-filter .date-picker-wrapper{
  display: flex;
  align-items: center;
  width: calc(100% - 100px);
  justify-content: center;
}
.dashbaord-filter.email-log .icon-btn-wrapper{
  display: flex;
  gap: 12px;
}
.dashbaord-filter.email-log .icon-btn-wrapper button{
  background-color: #d6eaff;
}
.popup-filter .get-report-btn{
  width: fit-content !important;
}
.legend-item-label {
  display: inline-block;
  width: fit-content;
}
.apexcharts-legend{
  gap: 4px;
}
.legend-item-value {
  display: inline-block;
  min-width: 18px;
  margin-left: 8px;
  text-align: left;
  font-weight: 600;
}
.table-footer{
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-end;
  padding-right: 30px;
  background: #eee;
  font-size: 14px;
}
.edit-field{
  display: flex;
  align-items: center;
  gap: 4px;
}
.edit-field .MuiSvgIcon-root{
  font-size: 16px;
}
.edit-field .MuiSelect-select{
  padding: 10px 8px !important;
}
.edit-field .MuiInputBase-root{
  margin: 4px 0;
}
.edit-field .MuiFormHelperText-root{
  display: none;
}
.edit-field .MuiFormControl-root{
  max-width: 160px ;
}

@media only screen and (max-width: 1439px){
  .add-user-form{
    width: 52%;
  }
  .inner-container{
    width: 70%;
  }
  .dashboard-card{
    padding-top: 8px;
    padding-bottom: 0;
  }
  .dashboard-card .MuiCardContent-root svg{
    font-size: 2.9vw !important;
    margin-bottom: 5px;
  }
  .dashboard-card .MuiCardContent-root .card-count{
    font-size: 2.5vw;
    margin-bottom: 5px;
  }
  .input-container{
    grid-template-columns: repeat(4,1fr);
    gap: 12px;
    grid-row-gap: 2px;
  }
  .get-report-btn{
    grid-column: span 4; 
    width: 283px;
    justify-self: end;
    text-align: right;
  }
  .email-log .table{
    height: calc(100% - 94px);
  }
}
@media only screen and (max-width: 1279px){
  h2{
    font-size: 1.2em;
  }
  .dashboard-container .MuiCard-root{
    padding: 10px 0;
  }
  .date-picker-container input{
    min-width: unset;
  }
  .add-user-form{
    width: 70%;
  }
  .dashbaord-filter{
    width: 76%;
  }

  .dashboard-container{
    height: calc(100% - 92px);
    overflow-y: auto;
  }
  .dashboard-container .row{
    flex-direction: column;
    margin-top: 0;
  }
  .dashboard-container .col{
    width: 80% !important;
    height: 40vw;
    margin-top: 16px;
  }
}
@media only screen and (max-width: 1199px){
  .block-heading{
    font-size: 1.14rem;
  }
  .dashboard-container{
    padding-left: 12px;
  }
  .dashboard-filter-wrapper{
    padding-left: 12px;
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .add-user-form{
    margin-top: 0px;
    /* margin-left: 0; */
    max-width: unset !important;
    /* width: 100%; */
  }
  .add-user-form.user{
    max-width: 550px !important;
  }
  .form-subheading{
    font-size: 1.14rem;
    margin-top: 0;
  }
  .dashboard-container{
    height: calc(100% - 76px);
  }
}
@media only screen and (max-width: 1023px){
  .inner-container{
    width: 80%;
  }
  
  .block-container{
    padding: 20px 12px;
  }
  .forgotLink{
    font-size: 14px !important;
  }
  .email-log-container .head button svg{
    font-size: 1.6rem;
  }
  .client-log-header{
    flex-direction: column;
    align-items: flex-end;
  }
  .client-log-header .email-heading{
    width: 100%;
    margin-bottom: 12px;
  }
  .client-info-wrapper{
    flex-direction: column;
    gap: 14px;
  }
  .client-info-wrapper .info-card {
    width: 100% !important;
  }
  .dashbaord-filter .date-picker-wrapper{
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 899px){
  .dashboard-container .MuiCard-root{
    max-width: unset;
  }
  .add-client .table-container .MuiGrid-container{
    display: block;
    margin-left: 0;
  }
  .add-client .table-container .main-wrapper{
    width: 100%;
    padding: 0;
  }
  .add-client .table-container .MuiGrid-item{
    width: 100% ;
    max-width: unset;
  }
  .add-client .table-container .MuiGrid-grid-sm-6{
    padding: 0 0 !important;
    padding-top: 16px !important;
  }
  .add-client .table-container .MuiGrid-grid-sm-6 .company-container{
    width: 100%;
  }
  .half-width-container{
    width: 100% !important;
    margin-left: 0 !important;
  }
  .half-width-container .MuiGrid-item{
      padding: 0 0!important;
      flex-basis: 100%;
      /* width: calc(100% - 40px) !important; */
      max-width: 100%;
  }
  .half-width-container .MuiGrid-item:first-of-type{
    padding-top: 15px !important;
  }
  .add-client .table-container .sub-container{
    padding: 0 0 !important;
    padding-top: 16px !important;
  }
  .full-width-field{
    margin-top: 0px !important;
  }
  .text-area{
    padding-left: 0;
  }
  .grid-container .MuiGrid-container{
    flex-direction: column;
    margin-left: 0 !important;
  }
  .email-log-container .add-user-form{
    padding: 16px;
    max-width: 100% !important;
  }
  .email-log-container{
    padding: 0;
  }
  .email-log-container .add-user-form:first-of-type{
    margin-top: 10px;
  }
  .dashboard-card .MuiCardContent-root svg{
    font-size: 4.2vw !important;
  }
  .dashboard-card .MuiCardContent-root .card-count{
    font-size: 4vw;
  }
  .activity-table.table{
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (max-width: 767px){
  .user-tabs-wrapper .MuiTab-root{
    font-size: 12px;
    padding: 14px;
  }
  .user-panels > div{
    padding: 12px 0;
  }
  .sub-header{
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: 0;
    border-bottom: 0;
  }
  .sub-header .MuiTextField-root{
    width: 100%;
    margin-bottom: 12px;
  }
  .sub-header button{
    width: fit-content;
    font-size: 14px !important;
  }
  .sub-header button svg{
    width: 0.8em;
    height: 0.8em;
  }
  .table{
    height: calc(100% - 124px);
  }
  .activity-table.table{
    height: calc(100% - 88px);
    padding: 5px;
  }
  .date-filter{
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  .add-user-form{
    width: 100%;
  }
  .inner-container{
    width: 90%;
  }
  .inner-container.form-wrapper{
    width: 100%;
  }
  .get-report-btn button{
    font-size: 12px;
    padding: 8px 10px;
  }
  .input-container{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
  }
  .inner-container .MuiFormHelperText-root{
      display: none;
  }
  .input-container > div{
    flex-basis: 48.5%;
    width: 48.5%;
    max-width: 48.5%;
    flex: none !important;
  }
  .get-report-btn{
    width: 100% !important;
    flex-basis: 100% !important;
    max-width: 100% !important;
    display: flex;
    justify-content: flex-end;
  }
  .email-log-table{
    height: calc(100% - 150px) !important;
  }
  .email-log-table.followup{
    height: calc(100% - 90px) !important;
  }
  .MuiDialogContent-root{
    padding: 16px 12px !important;
  }
  .popup-content .add-user-form{
    padding: 0;
  }
  .popup-wrapper .MuiTypography-h6{
    padding: 16px 12px !important;
  }
  .popup-wrapper .MuiTypography-h5{
    font-size: 16px !important;
  }
  .dashbaord-filter{
    width: calc(100% - 110px);
    align-items: flex-end;
    gap: 0px;
  }
  .dashbaord-filter.email-log{
    flex-direction: column;
    gap: 12px;
  }
  .dashbaord-filter .date-picker-wrapper{
    width: 100%;
  }
  .date-range-picker-wrapper input{
    font-size: 14px;
    padding: 16px 12px;
  }
  /* .dashbaord-filter .MuiFormControl-root:first-child, .dashbaord-filter .MuiFormControl-root:last-child{
    width: 100% !important;
  } */
  .report-heading{
    font-size: 18px;
  }
  .email-form{
    display: block;
  }
  .ql-editor{
    height: 260px !important;
  }
  .editor-container{
    margin-top: 16px;
  }
  .dashboard-container{
    height: calc(100% - 70px);
  }
  .dashboard-filter-wrapper{
    margin-top: 12px !important;
    margin-bottom: 6px !important;
  }
  .dashboard-container .col{
    width: 100% !important;
    height: 58vw;
    padding: 16px 12px;
  }
  .sub-header .search-wrapper{
    width: 100%;
  }
  .dashboard-container .followUp .MuiTabs-root{
    min-height: unset;
  }
  .dashboard-container .followUp .MuiTab-root{
    padding: 12px 16px;
    font-size: 12px !important;
    min-height: unset;
  }
  .popup-filter .input-container > div{
    width: 100%;
    max-width: 100%;
  }
}
@media only screen and (max-width: 599px){
  .login-container{
    width: 90%;
  }
  .menu-btn{
    top: 16px;
  }
  .add-client .form-wrapper{
    height: calc(100% - 90px);
  }
  .input-container > div{
    flex-basis: 47.5%;
    width: 47.5%;
    max-width: 47.5%;
    /* flex: unset !important; */
  }
  /* .input-container > div:nth-of-type(3){
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  } */
  .get-report-btn{
    grid-column-start: unset;
    width: 100% !important;
    flex-basis: 100% !important;
    max-width: 100% !important;
    justify-content: flex-end;
  }
  .email-log .table{
    height: calc(100% - 137px);
  }
  .fields-wrapper .main-wrapper .MuiGrid-item:last-of-type{
    padding-left: 0;
    margin-top: 12px;
  }
  .dashboard-card .MuiCardContent-root svg{
    font-size: 6.9vw !important;
    margin-bottom: 5px;
  }
  .dashboard-card .MuiCardContent-root .card-count{
    font-size: 6.2vw;
    margin-bottom: 5px;
  }
  .clear-filter-btn{
    width: 160px !important;
    max-width: unset !important;
    flex-basis: unset !important;
    padding-left: 10px !important;
  }
}
@media only screen and (max-width: 480px){
  .dashboard-container .col{
    height: 64vw;
  }
}
