.MuiDataGrid-columnHeaderTitleContainer  {
    background-color: #eee;
  }
.css-13cymwt-control{
  border-radius: 10px!important;
  border-color:grey!important;
  padding: 6px;
  z-index: 9;
}
.css-1jqq78o-placeholder ,.css-tj5bde-Svg {
color: grey!important;
}
.MuiGrid-root .MuiGrid-item .MuiGrid-grid-xs-6 .css-1osj8n2-MuiGrid-root{
  z-index: 99;
}

.stdropdown-container{
    border: 1px solid grey!important;
    border-radius: 10px!important;
    padding: 4px;

}
.stsearch-box input{
  border: none!important;
}
.stsearch-box input:focus{
  border: none!important;
}
/* .stdropdown-item span{
  display: none!important;
} */
.stsearch-box input:focus {
  outline: none; /* This removes the default focus outline */
  border: none;  /* This removes the border */
}
.container-height{
  height: 100%;
}
.email-heading {
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
  color: #202020;
}
.add-user-form.view-client{
  background-color: unset;
}
.view-client-wrapper{
  height: calc(100% - 92px);
  position: relative;
  margin-top: 16px;
}
.view-client-wrapper.open{
  gap: 20px;
}
.client-info-wrapper{
  display: flex;
  justify-content: space-between;
}
.client-info-wrapper .info-card{
  width: 38%;
  background: #f7f7f7;
  padding: 10px 20px;
  border-radius: 20px;
}
.client-info-wrapper .info-card:first-child{
  width: 60%;
}
.client-info-wrapper .col-wrapper{
  display: flex;
  justify-content: space-between;
}
.client-info-wrapper .col{
  width: 49%;
}
.client-info-wrapper .info{
  font-size: 14px;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: space-between;
}
.client-info-wrapper .info-card h3{
  font-size: 16px;
  text-align: left;
  margin-bottom: 8px;
  color: #202020;
}
.client-info-wrapper .info b{
  width: 38%;
  color: #5a5a5a;
  font-size: 12px;
}
.client-info-wrapper .info p{
  color: #5a5a5a;
  font-size: 12px;
  margin: 6px 0;
  width: 58%;
  max-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.client-table-container {
  width: 100% !important;
  height: calc(100% - 170px) !important;
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
}
.client-table-container.open{
  width: 70%;
}
.client-table-container .collapse-table-container{
  width: 49%;
  overflow-x: unset;
  padding: 0;
  height: auto !important;
  overflow-y: auto;
  background: #f9f9f9;
  border-radius: 20px;
  padding: 16px;
  color: #5a5a5a;
}
.client-table-container .collapse-table-container::-webkit-scrollbar{
  width: 4px;
}
.client-table-container .collapse-table-container::-webkit-scrollbar{
  border-radius: 2px;
  background: #ccc;
}
.close-sidebar{
  font-size: 10px !important;
  color: #2e2e2e !important;
}
.client-details-wrapper .close-sidebar.open{
  position: absolute;
  z-index: 100;
  left: -20px;
  top: 20px;
}
.client-details-wrapper {
  position: relative;
  text-align: left;
  width: 0;
  height: calc(100% - 24px) !important;
  background: #f9f9f9;
  border-radius: 20px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  margin-top: 16px;
  transition: 0.3s ease-in-out;
}
.client-details-wrapper .client-details{
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}
.client-details-wrapper.open{
  padding: 10px 8px;
  padding-left: 20px;
  width: calc(30% - 24px);
}
.client-details-wrapper.overlay{
  position: absolute;
  right: 0;
  z-index: 100;
}
.view-client-table{
  height: calc(100% - 38px);
  overflow: auto;
}
.client-details-wrapper .client-details::-webkit-scrollbar, .view-client-table::-webkit-scrollbar{
  width: 4px;
  height: 4px;
}
.client-details-wrapper .client-details::-webkit-scrollbar-thumb, .view-client-table::-webkit-scrollbar-thumb{
  border-radius: 2.5px;
  background: #ccc;
}

.client-subheading {
  border-bottom: 1px solid #eee;
  padding: 5px 0; 
}
.view-client-wrapper .collapse-table-container tbody tr td{
  padding: 10px 8px;
}
.view-client-wrapper .collapse-table-container p{
  text-align: center;
  width: 100%;
}

@media only screen and (max-width: 1024px){
  .client-details-wrapper.open{
    width: 250px;
  }
  .view-client-wrapper{
    overflow-y: auto;
  }
  .view-client-wrapper::-webkit-scrollbar{
    width: 4px;
  }
  .view-client-wrapper::-webkit-scrollbar-thumb{
    background-color: #ccc;
    border-radius: 2.5px;
  }
  .client-table-container{
    max-height: 300px;
    height: auto !important;
  }
  .client-table-container .collapse-table-container{
    width: 100%;
  }
  .client-table-container{
    flex-direction: column;
    max-height: 700px;
    height: auto;
    justify-content: flex-start;
    gap: 14px;
  }
}
@media only screen and (max-width: 767px){
  .email-heading{
    font-size: 14px;
  }
  .client-info-wrapper .col-wrapper{
    flex-direction: column;
  }
  .client-info-wrapper .col{
    width: 100%;
  }
  .group-records-head p{
    font-size: 12px;
  }
  .group-records-wrapper .group-event p{
    font-size: 12px;
  }
  .group-records-head{
    flex-wrap: wrap;
    gap: 8px;
  }
  .group-records-head .right-col{
    width: 100%;
  }
  .group-records-head p{
    width: 48% !important;
  }
  .group-records-head p:first-of-type{
    margin-bottom: 8px;
    width: 100% !important;
  }
}